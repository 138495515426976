import React from 'react';
import './reset-button.scss';
import { Button } from "@cargo/ui-kit/button/button";

export const ResetButton = ({isOverriding, overrideReset}) => {

    if ( !isOverriding || typeof overrideReset !== 'function' ) {
        return null 
    } else {
        return (
            <Button 
                className={`override-reset`}
                tooltip={`Reset`}
                onMouseDown={e=>{
                    e.preventDefault();
                    e.stopPropagation();
                    if (typeof overrideReset === 'function') {
                        if( document.activeElement ){
                            document.activeElement.blur()
                        }
                        overrideReset()
                    }
                }}
            >
                {/* <svg width="15" height="15" viewBox="0 0 15 15" fill="none"> */}
                {/*     <rect  */}
                {/*         x="3.64645"  */}
                {/*         y="7.53552"  */}
                {/*         width="5.5"  */}
                {/*         height="5.5"  */}
                {/*         transform="rotate(-45 3.64645 7.53552)"  */}
                {/*         fill="black"  */}
                {/*         stroke="black"  */}
                {/*         strokeWidth="0.5"/> */}
                {/* </svg> */}

                <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
                    <rect x="3.82322" y="7.53552" width="5.25" height="5.25" transform="rotate(-45 3.82322 7.53552)" fill="black" stroke="black" strokeWidth="0.25"/>
                </svg>



            </Button>
        )
    }

}