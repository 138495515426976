export const colors = [
	'#92B34E',
	'#FF9FBD',
	'#55C0B3',
	'#FFB800',
	'#745B5C',
	'#FF3D00',
	'#676DC6',
	'#C9C9C9',
	'#25AF3C',
	'#FFBFA6',
	'#5A97C2',
	'#B3ED00',
	'#5B5343',
	'#F1BE00',
	'#9A5AC3'
]