import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AlertContext } from '@cargo/ui-kit';
// Referenced from https://github.com/remix-run/history/blob/dev/docs/blocking-transitions.md 
export const useNavigationBlocker = ( shouldBlock, allowedRoutes, confirmCallback ) => {
	const history = useHistory();
	const [nextLocation, setNextLocation] = useState(null);
	const { openModal } = useContext(AlertContext);

	useEffect(() => {

		if ( !shouldBlock ) return;

		const unblock = history.block((tx) => {
			console.log('tx.pathname', tx.pathname);
			if( allowedRoutes.indexOf( tx.pathname ) !== -1 ) return true;

			// setNextLocation(tx.pathname);
			
			// if( window.confirm('Leaving this page will discard unpublished changes.') ){
			// 	unblock();
			// 	confirmCallback && confirmCallback();
			// 	history.push(tx.pathname);
			// } else {
			// 	setNextLocation(null);
			// }

			openModal({
				header: 'You have unpublished changes.',
				type: 'confirm',
				confirmButton: 'Discard',
				denyButton: 'Cancel',
				onConfirm: () => {
					unblock(); // Unblock to allow navigation
					confirmCallback && confirmCallback();
					history.push(tx.pathname); // Manually push the captured location
				},
				onDeny: () => {
					setNextLocation(null); // Clear the location if user cancels
				},
			});
			// Return false to prevent navigation until user confirms
			return false;
		});

		return () => {
			unblock();
		};
	}, [shouldBlock, history, openModal]);

	return nextLocation;
};