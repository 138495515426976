import React, {Component, Fragment, useState} from 'react';
import { isArray, isEqual } from 'lodash';

import './tag-input.scss'

class Tag extends Component {

	constructor(props) {

		super(props);
		this.ref = React.createRef();

		this.state = {
			value: this.props.value,
			hasError: false
		}

	}

	render() {

		return <div className={`tag${this.state.hasError ? ' error' : ''}`} data-value={this.state.value} >
			<input 
				type="text"
				value={this.state.value} 
				ref={this.ref}
				size={1}
				onBlur={this.onBlur}
				onKeyUp={this.onKeyUp}
				onChange={e => { 

					this.setState({
						value: e.currentTarget.value
					})

					// if this tag already exists, immediately update it
					if(this.props.id !== undefined) {
						this.updateValue(e.currentTarget.value);
					}

				}} 
				autoFocus={this.props.id === undefined ? true : false }
			/>

			<div className="delete-tag" onMouseDownCapture={this.onDelete}>
				<svg width="12" height="12" x="0px" y="0px" viewBox="0 0 13 13">
					<g strokeWidth="1" fill="none" fillRule="evenodd">
						<g transform="translate(1.000000, 1.000000)" stroke="#FFFFFF">
							<path d="M-0.2-0.2l11.5,11.5"></path>
							<path d="M11.2-0.2L-0.2,11.2"></path>
						</g>
					</g>
				</svg>
			</div>
		</div>

	}

	onBlur = (e) => {

		if(this.props.id === undefined) {
			// commit the tag
			this.updateValue(this.state.value === "" ? null : this.state.value);
		}

	}

	onKeyUp = (e) => {

		if(
			e.key === 'Enter'
		) {
			// commit the tag. If pressing enter on an empty tag, clear it.
			this.updateValue(this.state.value === "" ? null : this.state.value);
		}

	}

	onDelete = () => {
		this.updateValue(null);
	}

	updateValue = newValue =>  {

		const success = this.props.onChange(this.props.id, newValue);

		if(success === false) {
			this.setState({
				hasError: true
			});
		} else {
			if(this.state.hasError) {
				this.setState({
					hasError: false
				})
			}
		}

	}

}

let idCounter = 0;

export class TagInput extends Component {

		constructor(props) {
			super(props);

			this.state = {
				newTagInProgress: false,
				tags: this.getInternalTagState()
			}

		}

		getInternalTagState = () => {

			let tags = isArray(this.props.field?.value) ? this.props.field.value : [];

			// add an ID so we can assign proper keys for React (otherwise we run into re-rendering issues)
			return tags.map(tag => ({
				value: tag,
				id: `tag_${idCounter++}`
			}));

		}

		render() {

			return <div className="tag-input">
				<div className="tags">
					{
						// render existing tags 
						this.state.tags.map(tag => {
							return <Tag key={tag.id} id={tag.id} value={tag.value} onChange={this.onTagChange} />
						})
					}
					{ 
						// new tag button
						this.state.newTagInProgress ?
							<Tag key="__new_tag" onChange={this.onTagChange} value="" />
							:
							<div key="__new_tag_button" className="new-tag-button" onClick={this.startNewTag}>
								<svg version="1.1" x="0px" y="0px" viewBox="0 0 12 12">
									<g transform="translate(1.000000, 1.000000)">
										<path d="M-2.5,5h15"></path>
										<path d="M5-2.4v14.9"></path>
									</g>
								</svg>
							</div>
					}
				</div>
			</div>

		}

		componentDidUpdate(prevProps, prevState) {

			if(
				prevProps.field.value !== this.props.field.value
				&& !isEqual(prevProps.field.value, this.props.field.value)
			) {

				if(this.madeInternalChange !== true) {
					// the tags changed externally. Either by another user or by undo/redo. Re-init
					// the internal state to reflect the changes
					this.setState({
						tags: this.getInternalTagState()
					})
				}

				delete this.madeInternalChange
			}



			if(prevState.tags !== this.state.tags) {

				if ( this.props.form ) {
					this.props.form.setFieldValue(
						this.props.field.name, 
						this.state.tags.map(tag => tag.value), 
						false
					)
				}

			}

		}

		validateTag = (id, newValue) => {

			const duplicateTags = this.state.tags.filter(existingTag => existingTag.id !== id && existingTag.value === newValue);

			if(duplicateTags.length > 0) {
				return false;
			}

			return true;

		}

		onTagChange = (id, value) => {

			if(id === undefined) {

				if(value !== null && value !== "") {

					// check if the new value is valid
					if(!this.validateTag(id, value)) {
						return false;
					}

					// create the tag
					this.setState({
						tags: [
							...this.state.tags,
							{
								value,
								id: `tag_${idCounter++}`
							}
						]
					})
				}

				this.setState({
					newTagInProgress: false
				})

				return
			}

			const newTags = [...this.state.tags];
			const index = newTags.findIndex(tag => tag.id === id);

			if(value === null) {
				// delete
				newTags.splice(index, 1);
			} else {

				// check if the new value is valid
				if(!this.validateTag(id, value)) {
					return false;
				}

				// update
				newTags[index].value = value;

			}

			this.madeInternalChange = true;

			this.setState({
				tags: newTags
			})

		}

		startNewTag = (e) => {

			e.preventDefault();

			this.setState({
				newTagInProgress: true
			})

		}
}