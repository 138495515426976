import React, { Component } from 'react';
import { ContextMenuButton, ContextSubMenu, ContextMenuCheckbox, ContextMenu, MenuContext } from "@cargo/common/context-menu"
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { ProcessingAnimation } from '@cargo/ui-kit/processing/processing';
import './select.scss';

export class Select extends Component {

	constructor(props) {
		super(props);
		this.state = {
			optionText : "",
			default: "",
			selectWidth: 'auto'
		}
	
		this.selectRef = React.createRef();
	};

	setSelectText =()=> {

		if( this.props.multiple ){

			return

		} else if( this.props.useContextMenu){

			// get all nested children and look for the one with the value that matches the active one
			const flattenedChildren = _.flattenDeep(Array.from(this.props.children));
			const activeOption = flattenedChildren.find(child=>child.props.value == this.props.field.value);
			if( activeOption){
				this.setState({
					optionText: activeOption.props['display-text']
				})
			}



		} else if ( this.selectRef.current ){
			
			if(this.props.selectedIndex){
				this.selectRef.current.selectedIndex = this.props.selectedIndex
			}

			const displayText = this.selectRef.current.options[this.selectRef.current.selectedIndex]?.getAttribute('display-text');

			const optionText = this.selectRef.current.options[this.selectRef.current.selectedIndex]?.text;

			// Always try to display alt text, fall back to option visible text
			this.setState({
				optionText: displayText ? displayText : optionText
			})		
		
		}
	};

	// 	defaultValue={{ field.defaultValue }}
	render(){

		const {field, form, children, prefix, defaultDisplay, noArrows, disabled, label, noBars, barLeft, buttonSelect, buttonLabel, className, multiple, useContextMenu, processing, dynamicWidth, onChange} = this.props;

		const selectEl = (
			<div disabled={disabled} className={`select${noBars ? '' : ' bars'}${className ? ' '+className : ''}${barLeft ? ' barLeft' : '' }`}>
				<label>
					{ processing ? ( <ProcessingAnimation className="dark" /> ) : ( null )}
					{!buttonSelect && !multiple ?
						<div className="select-display">
							{ prefix ? (
								<div className="select-prefix">{prefix}</div>
							) : (
								null
							)}
							<div className="select-value">
								{defaultDisplay && field.value === 'default' ? defaultDisplay : this.state.optionText}
							</div>
						</div>
					:
						<div className="select-display">
							<span>{buttonLabel}</span>	
						</div>
					}

					{multiple || useContextMenu ?

						<ContextSelectMenu
							multiple={multiple}
							field={field}
							form={form}
						>
							{children}
						</ContextSelectMenu>

					:	<select
						ref={this.selectRef}
						name={field.name}
						value={field.value}
						onBlur={field.onBlur}
						onChange={(e) => {
							form.setFieldValue(field.name, e.target.value, false);
							onChange?.(e);
							this.updateSelectWidth();
						}}
						{...(dynamicWidth ? { style: { width: this.state.selectWidth } } : {})}
					>
						{children}
					</select>}
					{ noArrows ? (
						null
					) : (
						<div className="select-arrows">
							<div></div>
						</div>
					)}
				</label>
			</div>
		)

		if( label ){
			return(
				<div className={`label-select-group${noBars ? '' : ' bars'}${barLeft ? ' barLeft' : '' }`}>
					<div className="select-label">
						{label}
					</div>
					{selectEl}
				</div>
			)		
		}

		return selectEl

	};

	componentDidMount(){
		this.setSelectText();
		this.updateSelectWidth();
	}

	componentDidUpdate(prevProps){

		if (
			// update cover text when the value changes
			this.props.field.value != prevProps.field.value
			// Or when paginating in new content, the selected child might be added
			// after we set the initial cover text, but before the value changes.
			|| this.props.children.length !== prevProps.children.length
		){
			this.setSelectText();
			this.updateSelectWidth();
		}

	}

    updateSelectWidth() {
        if (this.props.dynamicWidth && this.selectRef.current) {
            const selectedOption = this.selectRef.current.options[this.selectRef.current.selectedIndex];

			const tempDiv = document.createElement('div');
            const selectStyles = window.getComputedStyle(this.selectRef.current);
			const displayText = selectedOption.getAttribute('display-text') ? selectedOption.getAttribute('display-text') : selectedOption.text;

            tempDiv.style.position = 'absolute';
            tempDiv.style.visibility = 'hidden';
            tempDiv.style.whiteSpace = 'nowrap';
            tempDiv.style.fontSize = selectStyles.fontSize;
            tempDiv.style.fontFamily = selectStyles.fontFamily;
            tempDiv.style.fontWeight = selectStyles.fontWeight;
            tempDiv.style.letterSpacing = selectStyles.letterSpacing;
            tempDiv.style.padding = selectStyles.padding;

            tempDiv.innerText = displayText;
            document.body.appendChild(tempDiv);
            const width = tempDiv.offsetWidth;
            document.body.removeChild(tempDiv);

            this.setState({ selectWidth: `${width}px` });
        }
    }
}


class ContextSelectMenu extends Component {
	constructor(props){
		super(props);
		this.state = {
			menuOpen: false,
		}
		this.contextSelectRef = React.createRef();
	}

	render(){

		return <>
		<select
			onMouseDown={this.triggerMenu}
			ref={this.contextSelectRef}
			multiple={this.props.multiple ? '' : null}
		>
			{this.props.children}
		</select>
		</>
	}

	innerUI= (children)=>{
		return <>{children.map(child=>{
			if( child.type === 'option'){
				return <ContextMenuCheckbox
					disabled={child.props.disabled}
					key={child.props.value+'key'}
					value={ this.props.multiple ? this.props.form.values[child.props.value] : child.props.value === this.props.field.value}
					label={child.props.children}
					onPointerUp={ (e) => {
						if( this.props.multiple){
							this.props.form.setFieldValue(child.props.value, !this.props.form.values[child.props.value])
						} else {
							this.props.form.setFieldValue(this.props.field.name, child.props.value)
						}
						
					}}						
				/>						
			} else if ( Array.isArray(child) ) {
				return this.innerUI(child)
			} else {
				return child
			}

		})}</>
	}

	triggerMenu=(e)=>{
		e.preventDefault();
		const rect = this.contextSelectRef.current.getBoundingClientRect();

		const flattenedChildren = _.flattenDeep(Array.from(this.props.children));
		const activeIndex = flattenedChildren.findIndex(child=> child.props.value == this.props.field.value);
		
		this.context.openMenu?.({
			innerUI: this.innerUI(this.props.children),
			style: {
				minWidth: rect.width+'px',
			},
			offset: {
				x: rect.x+-10, // emulate the 10px offset that the os does
				y: rect.y,
			},
			event: e,
			type: 'select',
			alignToScrollIndex: this.props.multiple && activeIndex > -1 ? false: true,
			scrollIndex: activeIndex,

			onOpen: ()=>{
				this.setState({
					menuOpen: true,
				});
			},
			onClose: ()=>{
				this.setState({
					menuOpen: false,
				});				
			}
		});

	}


	componentDidUpdate(prevProps){

		const {
			form: prevForm,
			children: prevChildren,
			...otherPrevProps
		} = prevProps;

		const {
			form,
			children,
			...otherProps
		} = this.props;

		if( this.state.menuOpen && !this.ticking){
			const flattenedChildren = _.flattenDeep(Array.from(this.props.children));
			const prevFlattenedChildren = _.flattenDeep(Array.from(prevProps.children));

			if (
					!_.isEqual(this.props.form.values , prevProps.form.values) || 
					!_.isEqual(flattenedChildren , prevFlattenedChildren )

			){
				this.ticking = true;
				this.context.updateInnerUI(this.innerUI(this.props.children));
				requestAnimationFrame(()=>{
					this.ticking = false;
				})
			}

		}




	}
}
ContextSelectMenu.contextType = MenuContext;