import React, {useRef, useState, useEffect} from 'react';
import './input.scss';

export const Input = ({field, className, autocomplete, spellcheck, innerRef, form, label, placeholder, type, icon, iconClass, noBars, barLeft, barRight, focusOnInit, ...props }) => {

	    const inputRef = innerRef ? innerRef : useRef(null);

		const [focused, setFocused] = useState(false); // tracks input focus
		const [isInitialFocused, setInitialFocus] = useState(false); // tracks initial input focus (used aggressive for autofocus)

		const autocompleteValue = autocomplete ? autocomplete : 'off'; 

		useEffect(() => {
			if( !focusOnInit ) return

			const timeoutId = setTimeout(() => {
			  if (inputRef.current && !isInitialFocused && focusOnInit) {
				inputRef.current.focus();
				setInitialFocus(true)
			  }
			}, 10);
		
			return () => clearTimeout(timeoutId);
		  }, [isInitialFocused]);

		const inputEl = (
			<div className={`text-input${className && !label ? ' '+className : ''}${noBars ? '' : ' bars'}${barLeft ? ' bar-left' : ''}${barRight ? ' bar-right' : ''}${focused ? ' focused' : ''}`} >
				<input 
					type={`${type ? type : 'text'}`}
					value={field.value} 
					ref={inputRef}
					spellCheck={ spellcheck ? 'true' : 'false' }
					autoComplete={ autocompleteValue }
					onFocus={(e) => { 
						setFocused(true)
						if( props?.onFocus ){
							props.onFocus(e)
						}
					}}
					onBlur={(e) => { 
						setFocused(false) 
						if( props?.onBlur ){
							props.onBlur(e)
						}
					}}
					disabled={props.disabled === true}
					onChange={e => { 

						if ( form ) {
							form.setFieldValue(
								field.name, 
								e.currentTarget.value, 
								false
							)
						}

					}} 
					placeholder={placeholder}
					autoFocus={focusOnInit ? true : false }
					name={field?.name}
					onKeyPress={(e) => { 
						if( props.onKeyPress ){
							props.onKeyPress(e);
						}
					}}
					onKeyDown={(e) => { 
						if( props.onKeyDown ){
							props.onKeyDown(e);
						}
					}}
				/>
			</div>
		)

		if( label ){
			return(
				<div className={`label-input-group${className ? ' '+className : ''}`}>
					<div 
						className={`input-label ${noBars ? '' : 'bars'}`}
						onClick={(e)=> {
							let inputEl = e.currentTarget.nextElementSibling.querySelector('input');
							let len = inputEl?.value.length;

						    if( len && len !== undefined ){
						        inputEl.focus();
						        inputEl.setSelectionRange(len, len+1)
						    } else if ( len === 0 ){
						    	inputEl.focus();
						    }
						}}
					>
						{ label }
					</div>
					{ inputEl }
				</div>
			)
		}

		if( icon ){
			return(
				<div className="input-icon-group">
					{ inputEl }
					<div className={`input-icon ${iconClass ? iconClass : ''}`} >
						{ icon }
					</div>
				</div>
			)
		}

		return inputEl

}

