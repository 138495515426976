import React, {useState, useEffect, useRef} from 'react';

export const ContextMenuButton = (props) => {

	const {label, className, children, checked, onPointerUp, useClickToReleaseDelay, immediatePointerUp, icon, ...otherProps} = props;

	// State from "passed" props in unique instances.
	const [hover, setHover] = useState(false);
	const [flickerOn, setFlickerOn] = useState(false);
	const [flickerOff, setFlickerOff] = useState(false);
	const [clickToReleaseDelayCompleted, setClickToReleaseDelayCompleted] = useState(false);

	const contextMenuReleaseTimer = useRef(null);

	useEffect(() => {
		contextMenuReleaseTimer.current = setTimeout(()=>{ setClickToReleaseDelayCompleted(true) }, 700)
		return ()=> {
			clearTimeout(contextMenuReleaseTimer.current)
		}
	}, []);

	return (
		<button
			className={`${className ? className+' ' : '' }${useClickToReleaseDelay && !clickToReleaseDelayCompleted ? 'prevent-release ' : '' }${checked ? 'checked ' : '' }${hover ? 'hover ' : '' }${flickerOn ? 'flicker-on ' : '' }${flickerOff ? 'flicker-off ' : '' }`}
			onPointerEnter={() => setHover(true) }
			onPointerLeave={() => setHover(false) }
			onPointerMove={()=> { 
				if( !clickToReleaseDelayCompleted ){
					setClickToReleaseDelayCompleted(true)
				}
			}}
			onPointerUp={ (e) => {
				if ( immediatePointerUp ){
					immediatePointerUp(e);
				}
				// Cancel delay.
				if( !clickToReleaseDelayCompleted ){
					setClickToReleaseDelayCompleted(true)
				}
				if (// flag is set, and delay is completed
					useClickToReleaseDelay && clickToReleaseDelayCompleted
					// flag is not set (Default)
					|| !useClickToReleaseDelay
				) {
					setFlickerOff(true)
					setTimeout(() => {
						setFlickerOn(true)
						setFlickerOff(false)
						setTimeout( ()=>{
							onPointerUp(e);
						}, 90)
					}, 90)
				}
			}}
			{...otherProps}
		>	
			<span className="before"></span>
			<div>
				{label}
				{children}
			</div>
			<span className={`after${icon ? ' icon' : ''}`}>{icon}</span>
		</button>
	);

};