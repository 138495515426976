import { useEffect } from 'react';
import { HotKeyManager } from './hotkey-manager';

export const useHotKey = (shortcut, config, callback, scope) => {
    useEffect(() => {
        const unregister = HotKeyManager.registerHotKey(shortcut, config, callback, scope);
        return () => {
            unregister();
        };
    }, [shortcut, config, callback, scope]);
};