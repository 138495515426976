import React, { Component, useState, useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { LoadingAnimation } from '@cargo/ui-kit/loader/loader';
import './message.scss';

let previouslyShownTooltipCount = 0;

export const MessageContext = React.createContext({
    messageText: null,
    duration: null,
    onOpen: () => null,
    onClose: () => null,
});

let lastOpts;

export const Message = (props) => {

    const initState = {
        messageText: null,
        duration: null,
        type: null,
        onOpen: () => null,
        onClose: () => null,
    }

    // State from "passed" props in unique instances.
    const [state, setState] = useState(initState);
    // showMessage > set visible > set dimensions > set position 
    const [visible, setVisible] = useState('none')

    let timeOut = null;

    const showMessage = (opts) => {

        if( _.isEqual(opts, lastOpts) ) {
            // prevent infinite loop
            return;
        }

        const options = _.defaults(opts);

        setState({
            messageText: options.messageText,
            duration: options.duration,
            type: options.type,
            preventClickout: options.preventClickout,
            preventDefaultClickHandlers: options.preventDefaultClickHandlers,
            className: options.className,
            pointerEvents: options.pointerEvents,
            onClick: options.onClick
        })

        setVisible('visible');

        if (state.onOpen) {
            state.onOpen();
        }

        lastOpts = opts


    }

    // Only for use in global click listener
    function setHide(e) {
        if( e && e.type === 'click' && state.preventClickout ){ 
            return 
        }

        if( visible === 'none' ){ return }

        hideMessage()
    }

    useEffect(() => {

        const contentFrame = document.getElementById('client-frame');

        if(state.preventDefaultClickHandlers !== true) {
            setTimeout(()=>{
                window.addEventListener("click", setHide);
            }, 10)
        }
        // Listen for a custom event indicating that an alert modal is opened.
        window.addEventListener('openAlertModal', setHide, false);

        if( state.preventDefaultClickHandlers !== true && contentFrame?.contentWindow?.document ){
            contentFrame?.contentWindow.document.addEventListener("click", setHide, true);
        }

        let toolTipTimeout;

        if(state.duration) {
            toolTipTimeout = setTimeout(() => {
                if (visible !== 'none') {
                    hideMessage()
                }
            }, state.duration);
        }

        // this will clear Timeout
        // when component unmount like in willComponentUnmount
        // and show will not change to true
        return () => {
            window.removeEventListener("click", setHide);
            window.removeEventListener('openAlertModal', setHide, false);
            if( state.preventDefaultClickHandlers !== true && contentFrame?.contentWindow?.document ){
                contentFrame?.contentWindow.document.addEventListener("click", setHide, true);
            }
            clearTimeout(toolTipTimeout);
        };

    }, [visible]);

    useEffect(() => {
        // cleanup function
        return () => { 
            const contentFrame = document.getElementById('client-frame');

            lastOpts = undefined;

            contentFrame?.contentWindow?.document.removeEventListener("click", setHide, true);
        }
    },[])


    const hideMessage = (e) => {

        lastOpts = undefined;

        setVisible('none')

        if (state.onClose) {
            state.onClose();
        }

        if( state.type === 'preview' ){
            window.store.dispatch({
                type: 'UPDATE_ADMIN_STATE', 
                payload: {
                    previewMessage: null
                }
            }); 
        }

    }

    const style = {};

    if(state.pointerEvents === true) {
        style.pointerEvents = "initial";
    }

    return (
        <MessageContext.Provider value={{
                showMessage : showMessage,
                hideMessage : hideMessage,
            }}>
            <>
                { visible !== 'none' && state.messageText !== '' && state.messageText  ? (
                    <div 
                        className={`notification-message${state.type === 'loading' ? ' loading' : ''}${state.type === 'preview' ? ' preview' : ''}`}
                        style={style}
                        onClick={(e)=>{
                            state.onClick?.(e);
                            if(state.preventDefaultClickHandlers !== true) {
                                e.preventDefault();
                                setVisible('none');
                            }
                        }}
                    >
                        { state.type === 'loading' ? (
                            <div className="message">
                                <LoadingAnimation height="75px" width="75px" className="linear large spinner white" />
                            </div>
                        ) : (
                             <div className={`message${state.className ? ' '+state.className : ''}`} dangerouslySetInnerHTML={{ __html: state.messageText }}></div>
                        )}  
                    </div>
                ) : (null)}
                { state.preventClickout && state.type !== 'preview' && visible === 'visible' ? (
                    <div 
                       onDrop={
                            e=> {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }                    
                        onMouseDown={
                            e=> {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }
                        onPointerUp={
                            e=> {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }
                        onClick={
                            e=> {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }
                        className={`clickout-layer`}
                    ></div>
                ) : (null)}
                { props.children }
            </>
        </MessageContext.Provider>
    )

}