import React, { useEffect, useState, useRef } from 'react';

export const SaveHelper = (props) => {
    // This is a helper component to trigger save when the Alert is triggered via custom event.
    // Without we don't have a good way to pass formik values to the save function.
    const propsRef = useRef(props);

    // Update the ref with the latest props
    useEffect(() => {
      propsRef.current = props;
    }, [props]);
  
    // This function will be called by the event listener
    const handleSaveEvent = (event) => {
      // Access the most up-to-date props through propsRef.current
      const form = propsRef.current.form;
      const formValues = props.renameFormKeys ? props.renameFormKeys(propsRef.current.formValues) : propsRef.current.formValues;
      const location = event.detail.location;
      
      props.saveChanges(form, formValues, location );
    };
  
    // Bind and unbind the event listener
    useEffect(() => {
      window.addEventListener('save-before-navigation', handleSaveEvent);
      return () => {
        window.removeEventListener('save-before-navigation', handleSaveEvent);
      };
    }, []); // Pass an empty array to run this effect only on mount and unmount


    return ( <></> );
}