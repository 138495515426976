import React, { Component } from 'react';
import './help-button.scss';

export class HelpButton extends Component {

	constructor(props) {
		super(props);
		this.buttonRef = React.createRef();
	}

	render() {

		const {
            articleID,
			label, 
			icon, 
			title, 
			innerPadding, 
			className, 
			toolTipIcon, 
			innerRef, 
			children, 
			iconLabel, 
			uiWindowIsOpen, 
			onMouseDown, 
			onPointerEnter, 
			onPointerLeave, 
			...otherProps
		} = this.props;

		return (
            <a 
                className={`help-button button-link ${className ? className : ''}`}
                // href="https://docs.cargo.site/domains-purchased-elsewhere"
                href="#"
                ref={this.buttonRef}
                // target="_blank"
                onClick={(e)=> {
                    console.log('works')
                    e.preventDefault();
                    if (articleID && Intercom) {
                        Intercom('showArticle', articleID)
                    }
                }}
          >
            <div className="help-icon">
              <span>?</span>
            </div>
          </a>  
		);

	}

};