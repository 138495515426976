/* Base Color and Background Mixins */
input,
div[contenteditable=true] {
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
  color: var(--ui-color-default);
  background: var(--ui-background-default);
  border: none;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}
input:focus,
div[contenteditable=true]:focus {
  outline-offset: inherit;
}

.ui-group .text-input {
  position: relative;
  height: var(--height-row);
  background: var(--ui-background-default);
}
.ui-group .text-input input {
  padding-top: calc(var(--ui-padding-default-vertical) - 1px);
  padding-bottom: calc(var(--ui-padding-default-vertical) + 1px);
  padding-left: var(--ui-padding-default-horizontal);
  padding-right: var(--ui-padding-default-horizontal);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: calc(var(--ui-lineHeight-default) - 0.1);
}
.ui-group .text-input input::-webkit-outer-spin-button,
.ui-group .text-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ui-group .text-input input[type=number] {
  -moz-appearance: textfield;
}
.ui-group .text-input input::placeholder {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}
.ui-group .text-input:after {
  outline: 1px dotted var(--ui-color-accent);
  opacity: var(--inputAfter-opacity);
  outline-offset: -5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  pointer-events: none;
}
.ui-group .text-input.error input, .ui-group .text-input.error input::placeholder {
  color: var(--ui-color-error);
}
.ui-group .text-input.inline-text-input {
  background: none;
  height: auto;
}
.ui-group .text-input.inline-text-input input[type=text] {
  padding: 0;
  background: none;
}
.ui-group .text-input.inline-text-input:after {
  display: none;
}

.ui-group .label-input-group {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 33.5% auto;
  grid-gap: var(--element-gap);
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
}
.ui-group .label-input-group .input-label {
  height: var(--height-row);
  padding: var(--ui-padding-default);
  background: rgba(var(--baseColor-default-rgb), 0.8);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  position: relative;
}
.ui-group .label-input-group .input-label.bars:before {
  background: var(--ui-details-background);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 2px;
  z-index: var(--layer-0);
}
.ui-group .label-input-group.error .input-label.bars:before {
  background: var(--ui-color-error);
  background: var(--ui-details-background);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 2px;
  z-index: var(--layer-0);
}
.ui-group .label-input-group .text-input.bars:before {
  background: none;
}
.ui-group .label-input-group.hover {
  background: rgba(var(--baseColor-default-rgb), 1);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.3);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.3);
  grid-template-columns: 30% auto;
}
.ui-group .label-input-group.hover .input-label {
  cursor: default;
  padding-right: 0;
  color: var(--ui-color-default);
}
.ui-group .label-input-group.hover .text-input:after,
.ui-group .label-input-group.hover .focused.text-input:after {
  opacity: var(--inputAfter-opacity);
}
.ui-group .label-input-group.password-placeholder input::placeholder {
  color: rgba(var(--baseColor-default-reverse-rgb), 1);
  fill: rgba(var(--baseColor-default-reverse-rgb), 1);
}

.ui-group .input-checkbox-group {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr var(--height-row);
  grid-gap: var(--element-gap);
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
}

.ui-group .input-icon-group {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto var(--height-row);
  grid-gap: var(--element-gap);
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
}
.ui-group .input-icon-group .input-icon {
  height: var(--height-row);
  width: var(--height-row);
  position: relative;
  cursor: pointer;
}
.ui-group .input-icon-group .input-icon:active {
  opacity: var(--opacity-downstate-default);
}
.ui-group .input-icon-group .input-icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ui-group .text-input.suggest {
  cursor: text;
}

.ui-group .bars .text-input.suggest {
  margin-left: 2px;
}

.ui-group .text-input .visible-hint {
  transform: translate(-1px, 0);
}

.ui-group span.measure {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  visibility: hidden;
}

.autosize {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui-group .pid-field {
  display: inline-grid;
  vertical-align: top;
  align-items: center;
  position: relative;
}
.ui-group .pid-field span {
  align-self: end;
}

input:-webkit-autofill, input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px var(--ui-background-default) inset;
  -webkit-text-fill-color: var(--ui-color-flat-reverse);
}