import React, { useState, useEffect, useRef } from 'react';

const ContentEditable = ({ initialValue, style, tag: Tag = 'div' }) => {
	const [content, setContent] = useState(initialValue)
	const ref = useRef(null);

	const handleInput = (e) => {
		setContent(e.target.innerText);
	};

	useEffect(() => {
		if (ref.current && ref.current.innerText !== initialValue) {
			ref.current.innerText = initialValue;
		}
	}, [initialValue]);

	return (
		<Tag
			ref={ref}
			contentEditable="true"
			spellCheck={false}
			onInput={handleInput}
			style={style}
			suppressContentEditableWarning={true}
		/>
	);
};

export default React.memo(ContentEditable, (prevProps, nextProps) => {
	// Prevent re-render if only content changes, but not initialValue
	return prevProps.initialValue === nextProps.initialValue && prevProps.style === nextProps.style;
});