export const
	FRONTEND_DATA = {},
	CRDTState = Object.freeze({
		New: 0,
		Published: 1,
		Draft: 2,
		Deleted: 3
	}),
	PublishState = Object.freeze({
		Error: -1,
		Publishing: 0,
		Published: 1,
		Draft: 2,
		Discarding: 3,
		Discarded: 4,
		SavingAs: 5
	}),
	YTransactionTypes = Object.freeze({
		Undoable: 0,
		Publishable: 1,
		NotUndoable: 2,
		NotPublishable: 3,
		NotUndoableNotPublishable: 4
	})