/* Base Color and Background Mixins */
div.save-cancel-preview-area.static {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  height: auto;
  padding-left: 15px;
  pointer-events: none;
}
div.save-cancel-preview-area.static:before {
  content: "";
  top: -1px;
  position: absolute;
  height: 1px;
  background: var(--ui-border-bars);
  width: calc(100% - var(--spacing-1));
  left: var(--spacing-1);
}
div.save-cancel-preview-area.static div.save-overflow {
  display: block;
  border: none;
  width: auto;
  height: auto;
  bottom: calc(var(--ui-padding-uiWindow) * -1);
  right: calc(var(--ui-padding-uiWindow) * -1 - 1px);
  z-index: 9;
  line-height: var(--lineHeight-default);
}
div.save-cancel-preview-area.static div.save-overflow div.ui-group.save-cancel section.save {
  position: relative;
  display: block;
  border: none;
  width: 100%;
  height: auto;
  z-index: 9;
  pointer-events: all;
  padding: var(--ui-padding-uiWindow);
  line-height: var(--lineHeight-default);
}
div.save-cancel-preview-area.static div.save-overflow div.ui-group.save-cancel section.save .save-cancel-buttons {
  justify-content: flex-start;
  pointer-events: all;
}
div.save-cancel-preview-area.static div.save-overflow div.ui-group.save-cancel section.save .save-cancel-buttons button.confirm {
  margin-right: 0;
  margin-left: 5px;
  background: rgba(var(--baseColor-default-reverse-rgb), 1);
  font-weight: 400;
}
div.save-cancel-preview-area.static div.save-overflow div.ui-group.save-cancel section.save .save-cancel-buttons button.preview {
  margin-left: auto;
}
div.save-cancel-preview-area.static div.save-overflow div.ui-group.save-cancel section.save .save-cancel-buttons button.cancel,
div.save-cancel-preview-area.static div.save-overflow div.ui-group.save-cancel section.save .save-cancel-buttons button.preview {
  background: rgba(var(--baseColor-default-rgb), 1);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  font-weight: 400;
  outline: 1px solid rgba(var(--baseColor-default-reverse-rgb), 0.15);
  outline-offset: -1px;
}
div.save-cancel-preview-area.static div.save-overflow div.ui-group.save-cancel section.save .save-cancel-buttons button.confirm.disabled,
div.save-cancel-preview-area.static div.save-overflow div.ui-group.save-cancel section.save .save-cancel-buttons button.cancel.disabled,
div.save-cancel-preview-area.static div.save-overflow div.ui-group.save-cancel section.save .save-cancel-buttons button.preview.disabled {
  opacity: 0.6 !important;
  pointer-events: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}