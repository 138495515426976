.notification-message {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: calc(var(--layer-9) + 99);
}
.notification-message.preview {
  align-items: flex-start;
  justify-content: flex-end;
  padding: 3rem;
}
.notification-message .message {
  background: var(--ui-load-spinner-large-background-color);
  border-radius: 11px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 11px 45px 13px;
  line-height: 1.3;
  text-align: center;
  font-family: var(--fontFamily-default);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}
.notification-message .message .intercom-link,
.notification-message .message .email-link {
  cursor: pointer;
  text-decoration: underline;
  color: #fff;
}
.notification-message .message .intercom-link:active,
.notification-message .message .email-link:active {
  opacity: 0.7;
}
.notification-message .message.tall {
  padding-top: 16px;
  padding-bottom: 18px;
}
.notification-message .message em-emoji {
  font-size: 24px;
  line-height: 0;
  display: inline-block;
}
.notification-message .message.url-updated {
  min-width: 430px;
}
.notification-message.loading .message {
  background: var(--ui-load-spinner-large-background-color);
  border-radius: var(--ui-load-spinner-large-background-radius);
  height: 130px;
  width: 130px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-message .linear-spinner.large svg {
  height: 75px;
  width: 75px;
}

body.safari .notification-message .message em-emoji {
  font-size: 36px;
  line-height: 0;
  transform: translate(0, 3px);
}