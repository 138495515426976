/* Base Color and Background Mixins */
.ui-group {
  --selectOptions-margin: 10px;
}

.ui-group .select {
  position: relative;
}
.ui-group .select select {
  opacity: 0;
  height: var(--height-row);
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 14px;
  position: relative;
}
.ui-group .select select[multiple] {
  display: block;
  border-width: 0px;
  margin: 0;
  padding: 0;
}
.ui-group .select option {
  -webkit-appearance: none;
  background-color: #E9E9EA !important;
}
.ui-group .select label {
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
}
.ui-group .select label .caution-processing {
  z-index: 2;
  border-radius: var(--ui-button-border-radius);
}
.ui-group .select label .select-display {
  display: flex;
  grid-auto-flow: column;
  grid-template-columns: min-content auto;
  grid-gap: 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: var(--ui-padding-default);
  padding-right: var(--height-row);
  padding-left: 15px;
  height: var(--height-row);
  background: var(--ui-background-default);
  pointer-events: none;
  border-radius: var(--ui-button-border-radius);
  color: var(--ui-color-default);
}
.ui-group .select label .select-display span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ui-group .select label .select-prefix,
.ui-group .select label .select-value {
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: var(--ui-background-default);
  color: var(--ui-color-default);
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.ui-group .select label .select-arrows {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 2px;
  bottom: 0;
  width: var(--height-row);
}
.ui-group .select label .select-arrows div {
  width: var(--height-row);
  height: var(--height-row);
  background-repeat: no-repeat;
  background-position: 50%;
  opacity: var(--ui-arrow-opacity);
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='15' viewBox='0 0 10 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg %3E%3Cpath d='M5 13.3929L0.909091 9.375L0 10.1786L5 15L10 10.1786L9.09091 9.375L5 13.3929Z' fill='black'/%3E%3Cpath d='M5 1.60714L9.09091 5.71429L10 4.91071L5 0L0 4.91071L0.909091 5.71429L5 1.60714Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
}
.ui-group .select.barLeft:before {
  background: var(--ui-details-background);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 2px;
  z-index: var(--layer-0);
}

.dark .ui-group .select label .select-arrows div {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='15' viewBox='0 0 10 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg %3E%3Cpath d='M5 13.3929L0.909091 9.375L0 10.1786L5 15L10 10.1786L9.09091 9.375L5 13.3929Z' fill='rgba(255, 255, 255, .85)'/%3E%3Cpath d='M5 1.60714L9.09091 5.71429L10 4.91071L5 0L0 4.91071L0.909091 5.71429L5 1.60714Z' fill='rgba(255, 255, 255, .85)'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.ui-group .label-select-group {
  display: grid;
  position: relative;
  grid-auto-flow: column;
  grid-template-columns: 33.5% auto;
  grid-gap: var(--element-gap);
}
.ui-group .label-select-group .select-label {
  padding: var(--ui-padding-default);
  height: var(--height-row);
  background: rgba(var(--baseColor-default-rgb), 0.8);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  white-space: nowrap;
}
.ui-group .label-select-group .select.bars:before {
  background: none;
}
.ui-group .label-select-group .select.bars:after {
  background: none;
}
.ui-group .label-select-group.bars:before {
  background: var(--ui-details-background);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 2px;
  z-index: var(--layer-0);
}
.ui-group .label-select-group.bars:after {
  background: var(--ui-details-background);
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 2px;
  z-index: var(--layer-0);
}

.ui-group > * + div.combo-input,
.ui-group > * + div.combo-select,
.ui-group > * + div.select.combo-select,
.ui-group > * + div.text-input.combo-input,
.ui-group > * + label.checkbox.combo-input {
  margin-top: 0px !important;
}

.ui-group > * + div.combo-input + div.input-checkbox-group,
.ui-group > * + label.checkbox.combo-input + div.input-checkbox-group,
.ui-group > * + div.text-input.combo-input + div.input-checkbox-group {
  margin-top: var(--selectOptions-margin);
}

.ui-group > * + .select,
.ui-group > .select + *:not(.more-actions):not(.no-margin) {
  margin-top: var(--selectOptions-margin);
}

.ui-group details .ui-group > * + .select {
  margin-top: 0;
}

.ui-group details .select label .select-display {
  border-radius: 0;
  padding-left: var(--ui-padding-default-horizontal);
}
.ui-group details .select label .select-arrows {
  right: 0;
}

.ui-group .text-select.select {
  display: flex;
  margin-top: 0;
}
.ui-group .text-select.select select {
  height: auto;
  width: fit-content;
  cursor: pointer;
}
.ui-group .text-select.select label .select-display {
  height: auto;
  padding: 0;
  background: none;
}
.ui-group .text-select.select label .select-display .select-prefix,
.ui-group .text-select.select label .select-display .select-value {
  font-family: var(--fontFamily-CargoDiatype);
  font-size: var(--fontSize-default);
  background: none;
}
.ui-group .text-select.select label .select-arrows {
  visibility: hidden;
}