import React from 'react';
import { Button } from './button';

export const MoreButton = (props) => {

	const {children, className, ...otherProps} = props;

	return (
		<Button
			label={
				<svg width="14" height="4" viewBox="0 0 14 4" fill="none">
					<circle cx="2.27979" cy="1.67261" r="1.5" fill="black"/>
					<circle cx="7.27979" cy="1.67261" r="1.5" fill="black"/>
					<circle cx="12.2798" cy="1.67261" r="1.5" fill="black"/>
				</svg>
			}
			className={`more-button${className ? ' '+className : ''}`}
			{...otherProps}
		>
			{children}
		</Button>
	);

};