import React from 'react';
import './text-button.scss';
    
export const TextButton = ({text, label, onClick, onMouseDown, ...props}) => {

    return (
        <button 
            className={`text-button ${props.className ? props.className : ''}`} 
            onClick={(e) => { onClick ? onClick(e) : null }} 
            onMouseDown={(e) => { onMouseDown ? onMouseDown(e) : null }} 
        >
            {text ? text : label ? label : '' }
        </button>
    )
}