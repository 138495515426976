import React, { useEffect, useRef, useState} from "react";

import './textarea.scss';

export const TextArea = ({form, field, name, className, bars, placeholder, maxLength, rows, staticHeight, focusOnInit, ...props}) => {

		const textareaRef = useRef(null);
		const [currentValue, setCurrentValue ] = useState("");

		useEffect(() => {
		    if( !staticHeight ){
		    	textareaRef.current.style.height = "0px";
		    	const scrollHeight = textareaRef.current.scrollHeight;
		    	textareaRef.current.style.height = scrollHeight + "px";
		    }
		}, [currentValue]);

		const focusInputEnd = () => { 
		    let inputLength = textareaRef?.current?.value.length;
		    if( textareaRef?.current && inputLength !== undefined ){
		        textareaRef?.current.focus();
		        textareaRef?.current.setSelectionRange(inputLength, inputLength+1)
		    }
		}

		useEffect(() => {
			if( focusOnInit ){
				setTimeout(()=>{
					focusInputEnd();	
				}, 5)
				
			}
		},[])

		return(
			<div 
				className={`text-area${className ? ' '+className : ''}${bars ? '' : ' bars'} `}
				onMouseDown={ focusInputEnd }
			> 
				<textarea 
					name={name}
					maxLength={maxLength ? maxLength : '600'}
					type="text"
					placeholder={placeholder ? placeholder : ''}
					rows={rows ? rows : '2'}
					ref={textareaRef}
					value={field.value}
					id={props?.id}
					onChange={e => { 

						setCurrentValue(e.target.value);

						if ( form ) {
							form.setFieldValue(
								field.name, 
								e.currentTarget.value, 
								false
							)
						}

					}} 
				>
				</textarea>	
			</div>
		)
		
}

